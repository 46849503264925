import { defineStore } from 'pinia';

export const DirectorStore = defineStore({
    id: 'directorStore',
    state: () => ({
        directors: {
            "nobody": "Noone"
        },
    }),
    actions: {
        setDirectors(directors: object) {
            // @ts-ignore
            this.directors = directors;
        },
        setDirector(id: string, name: string) {
            // @ts-ignore
            this.directors[id] = name;
        },
        getDirectorById(id: string): string {
            // @ts-ignore
            return this.directors[id];
        },
    },
    getters: {
        getDirectors(): object {
            return this.directors;
        },
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'flxDirectors',
                storage: localStorage,
            },
        ],
    },
});
