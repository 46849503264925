import { defineStore } from "pinia";

export const CategoriesStore = defineStore({
  id: "categoriesStore",
  state: () => ({
    categories: [],
    featured: [],
    language: "es",
  }),
  actions: {
    setFeatured(featured: string[]) {
      // @ts-ignore
      this.featured = featured;
    },
    setCategories(categories: object[], lang: string) {
      this.language = lang;
      // @ts-ignore
      this.categories = categories;
    },
  },
  getters: {
    getLanguage(): string | null {
      return this.language;
    },
    getCategories(): object[] {
      // @ts-ignore
      return this.categories;
    },
    getFeatured(): string[] {
      return this.featured;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxCategories",
        storage: localStorage,
      },
    ],
  },
});
