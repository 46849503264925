import { defineStore } from "pinia";

export const ProfileStore = defineStore("profileStore", {
  state: () => ({
    currentProfile: {},
    profiles: [],
  }),
  actions: {
    destroy() {
      this.currentProfile = {};
      this.profiles = [];
    },
    setCurrentProfile(profile: object) {
      this.currentProfile = profile;
    },
    setProfiles(profiles: object[]) {
      // @ts-ignore
      this.profiles = profiles;
    },
  },
  getters: {
    isProfileSelected(): boolean {
      // @ts-ignore
      return this.currentProfile.name !== undefined;
    },
    getCurrentProfile(): any {
      // @ts-ignore
      return this.currentProfile;
    },
    getProfiles(): object[] {
      // @ts-ignore
      return this.profiles;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "flxUserProfile",
        storage: localStorage,
      },
    ],
  },
});
