import {defineStore} from 'pinia';

export const FavoriteStore = defineStore('favoriteStore',{
    state: () => ({
        favorites: [],
    }),
    actions: {
        setFavorites(favorites: string[]) {
            // @ts-ignore
            this.favorites = favorites;
        },
        removeFavorite(id: string) {
            // @ts-ignore
            this.favorites = this.favorites.filter((favorite: string) => favorite !== id);
        }
    },
    getters: {
        getFavorites(): string[] {
            // @ts-ignore
            return this.favorites;
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'flxFavorites',
                storage: localStorage,
            }
        ]
    }
});
